import L from "leaflet";

export const iconPerson = L.icon({
	iconUrl:
		" https://res.cloudinary.com/drhj3sc2o/image/upload/v1679596306/Map-Marker-PNG-File_wfrufx.png",

	iconSize: [30, 40],
	// iconAnchor: [22, 94],
	// popupAnchor: [-3, -76],
	// shadowSize: [68, 95],
	// shadowAnchor: [22, 94],
});
